import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
// Import pdfmake-wrapper and the fonts to use
import {Columns, Img, PdfMakeWrapper, Table, Txt, Ul} from "pdfmake-wrapper";
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import {capacitacion, encuestaSegura, examen, user} from "../../../../interfaces";
import * as jsBarcode from "jsbarcode";
import {environment} from "src/environments/environment";
import {AngularFireFunctions} from "@angular/fire/functions";
import {UserService} from "./user.service";
import * as moment from "moment";
import {cloneDeep} from "lodash";
// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);
@Injectable({
	providedIn: "root"
})
export class PdfServiceService {
	baseUrl: string = "https://beneoaulavirtual.cl";
	constructor(private http: HttpClient, private _aFirestore: AngularFirestore, private _aFunctions: AngularFireFunctions, private _user: UserService) {}
	/**Generar Reglamento aceptado pdf*/
	async generateReglamentoAceptadoPdf(user: user) {
		try {
			const pdf = new PdfMakeWrapper();
			/** Ajustamos el tamaño de la pagina */
			//pdf.pageSize('A4');
			pdf.pageSize({width: 605, height: 776});
			pdf.pageMargins([20, 20, 20, 20]);
			pdf.styles({
				rut: {
					fontSize: 16,
					color: "#4d4d4d",
					bold: false,
					alignment: "center"
					//margin: [0, 10, 0, 0],
				}
			});
			const logo = await this.getLogo();
			// Principal
			pdf.add(
				new Columns([
					await new Img(<string>logo).fit([180, 180]).build(),
					new Txt("BENEO Orafti Chile * Orafti Chile S.A * Km. 445 Ruta 5 Sur \n Comuna de Pemuco * XVO región * Casilla #324 Chillán * Chile").fontSize(10).relativePosition(0, 20).color("#00000").end
				]).end
			);
			// Title${new Txt('Orafti Chile S.A.').bold().end}
			pdf.add(new Txt("Comprobante de entrega R.I.O.H.y S.").margin([0, 25, 0, 0]).alignment("left").italics().bold().fontSize(16).end);
			pdf.add(
				new Txt([`En cumplimiento del Código del Trabajo, recibi de `, new Txt("Orafti Chile S.A.").bold().end, " un ejemplar del Reglamento interno de Orden, Higiene y Seguridad."])
					.alignment("justify")
					.margin([0, 10, 0, 0])
					.fontSize(13).end
			);
			pdf.add(
				new Txt(
					"En conformidad con lo dispuesto en los artículos 153 y siguientes del Código del Trabajo y en el artículo 67 de la Ley N° 16.744, acuso de recibo del presente Reglamento Interno de Orden, Higiene y Seguridad de Orafti Chile S.A., entregado gratuitamente por la Empresa. El cual me comprometo a acatar y cumplir íntegramente."
				)
					.alignment("justify")
					.margin([0, 8, 0, 0])
					.fontSize(13).end
			);
			pdf.add(
				new Txt("En caso de no comprender el verdadero significado de alguno de los artículos, me comprometo a solicitar explicaciones a mis superiores respectivos.").fontSize(13).margin([0, 20, 0, 0]).alignment("justify").end
			);
			pdf.add(
				new Table([
					["", "Nombre:", `${user.firstname} ${user.lastname}`, ""],
					["", "RUT:", `${user.RUT}`, ""],
					["", "Cargo", `${user.cargo}`, ""]
				])
					.margin([0, 20, 0, 0])

					.width("auto")
					.layout("lightHorizontalLines").end
			);
			pdf.add(new Txt("√ Estoy de acuerdo").alignment("justify").fontSize(15).margin([0, 10, 0, 10]).bold().end);
			const imageSud = (await this.getImageLocalFromUrl("assets/img/sudzucker.png")) as string;
			pdf.add(await new Img(imageSud).fit([100, 100]).absolutePosition(500, 710).build());
			pdf.add(
				new Txt([new Txt("Orafti Chile S.A.").bold().end, " * KM. 445 Ruta 5 Sur * Comuna de Pemuco * XVI región * Casilla #324 * Chillan * Chile * Phone +56 422 45 80 00 * Fax +56 422 45 80 41"])
					.absolutePosition(20, 720)
					.color("#1c619b")
					.fontSize(7).end
			);
			pdf.add(new Txt(["www.beneo.com   contact@beneo.com  RUT 77.894.990-3"]).absolutePosition(180, 730).color("#1c619b").fontSize(7).end);
			if (environment.production) {
				pdf.create().download(`reglamento-aceptado-${user.firstname}-${user.lastname}-${new Date().toLocaleDateString()}.pdf`);
			} else {
				pdf.create().open("", window.open("", "_blank"));
				// pdf.create().download('certificado_'+examen.codigo_unico+"_"+new Date().toLocaleDateString());
			}
		} catch (err) {}
	}

	/**@description Metodo para generar pdf de normativas */
	async generateNormativas(method: "descargar" | "enviar") {
		try {
			if (method === "descargar") {
				const a = document.createElement("a");
				a.href = "assets/docs/RIOHS_Beneo_Orafti.pdf";
				a.download = "RIOHS_Beneo_Orafti.pdf";
				a.click();
				a.remove();
				return;
			} else if (method === "enviar") {
				const result = await new Promise(async (res, rej) => {
					const url = `${window.origin}/assets/docs/RIOHS_Beneo_Orafti.pdf`;
					console.log(url);
					const response = await this.http.get(url, {responseType: "blob"}).toPromise();
					// Obtenemos el lector de archivos
					const reader = new FileReader();
					/** Una vez finalice el reader */
					reader.onloadend = () => {
						const base64Data = reader.result;
						//  console.log(base64Data);
						res(base64Data);
					};
					reader.onerror = () => {
						rej("Ocurrio un error");
					};
					reader.readAsDataURL(response);
				});
				//  console.log(result);
				return result as string;
			}
		} catch (err) {
			throw err;
		}
	}

	/**
	 * @description Metodo para crear el certificado PDF de aprobacion del curso
	 * @param examen Examen donde sacaremos toda la data
	 * @param getBase64 Booleano para obtener el base64 del pdf
	 *
	 */
	async generateCertificado(examen: examen, getBase64?: boolean) {
		try {
			const pdf = new PdfMakeWrapper();
			/** Ajustamos el tamaño de la pagina */
			pdf.pageSize({width: 600, height: 776});
			pdf.pageMargins([0, 20, 0, 0]);

			/** definimos los estilos */
			pdf.styles({
				rut: {
					fontSize: 16,
					color: "#4d4d4d",
					bold: false,
					alignment: "center"

					//margin: [0, 10, 0, 0],
				},
				recibido: {
					color: "#4d4d4d",
					fontSize: 13,
					alignment: "center",
					margin: [0, 0, 70, 0],
					width: 100
				},
				normal: {
					color: "#4d4d4d",
					fontSize: 14
				},
				capName: {
					color: "#4d4d4d",
					fontSize: 18,
					bold: true,
					alignment: "center"
				},
				name: {
					fontSize: 17,
					color: "#4d4d4d",
					bold: true,
					alignment: "center",
					margin: [0, 0, 0, 0],
					noWrap: false
				}
			});

			let cap: capacitacion;
			if (!examen.capacitacion) {
				cap = cloneDeep(await this._aFunctions.httpsCallable("getCapById")({capId: examen.capId}).toPromise());
			} else {
				cap = cloneDeep(examen.capacitacion);
			}
			/** Background */
			pdf.background({
				image: await this.getImageLocalFromUrl("assets/img/fondo_pdf.jpg"),
				width: 600
			});

			// Certificado electronico
			const textCert = new Txt("Certificado Electrónico").color("white").fontSize(12).alignment("center").noWrap().absolutePosition(450, 30).end;
			pdf.add(textCert);

			/** Ponemos el logo */
			/*   const imgLogo:any = await this.getLogo();
      console.log(imgLogo);
      const logo = await new Img(imgLogo).alignment('center').fit([100,100]).margin([0,10,0,0]).build();
      pdf.header(logo);*/
			/** Ponemos la data del usuario */
			let name;
			let rut;
			// let firma1;
			// let firmaRut;
			console.log(examen.user);
			if (!examen.user) {
				const usuario = await this._aFunctions.httpsCallable("getUserByUid")({uid: examen.uid}).toPromise();
				/** Seteamos al usuario */
				examen.user = {name: usuario.firstname + " " + usuario.lastname, RUT: usuario.RUT};
			}
			name = new Txt(examen.user.name).style("name").absolutePosition(200, 170).end;
			rut = new Txt(examen.user.RUT).style("rut").absolutePosition(200, 190).end;

			/** Contenido */
			const cursoText = new Txt("CURSO").color("#0f4f8c").fontSize(15).bold().absolutePosition(20, 115).end;
			pdf.add(cursoText);
			const nameCurso = cap.name.length > 26 ? cap.name.substring(0, cap.name.lastIndexOf(" ", 26)) + "\n" + cap.name.substring(cap.name.lastIndexOf(" ", 26) + 1) : cap.name;
			const nombreCurso = new Txt(nameCurso).fontSize(14).bold().color("#6c6c6c").absolutePosition(20, 135).end;
			pdf.add(nombreCurso);
			// Si la capacitacion tiene contenido
			if (cap.contenido && cap.contenido.length > 0) {
				const contenidoText = new Txt("Contenido").fontSize(13).color("#6183b1").absolutePosition(25, 180).end;
				pdf.add(contenidoText);
				const array = [];
				cloneDeep(cap.contenido).forEach((c) => {
					if (c.name.length > 30) {
						// Ubicamos el substring mas cercano al 30 que tenga un espacio
						const index = c.name.lastIndexOf(" ", 30);
						// Agregamos un \n para que quede mejor
						c.name = c.name.substring(0, index) + "\n" + c.name.substring(index + 1);
					}
					array.push(c.name);
				});
				const listaCotenido = new Ul(array.map((c) => new Txt(c).fontSize(12).end)).color("#222222").fontSize(12).alignment("left").absolutePosition(20, 210).end;
				pdf.add(listaCotenido);
			}

			/** */

			// Agregamos una raya
			pdf.add(new Txt("_______________________________________________").absolutePosition(200, 200).alignment("center").end);
			pdf.add(new Txt("Curso Aprobado:").alignment("center").absolutePosition(200, 215).style("normal").end);

			const capName = new Txt(cap.name).style("capName").absolutePosition(200, 230).relativePosition(0, 1).end;
			pdf.add(capName);

			/** Colocamos otra data */
			const text = new Txt('He recibido la capacitación "' + cap.name + '", impartido por el relator "' + cap.relator + '", duración del curso "' + cap.horas + '" horas').style("recibido").relativePosition(30, 30).end;
			pdf.add(text);

			// FIRMA
			//    firma1 = new Txt(examen.user.name).absolutePosition(400, 345).alignment('center').style('normal')
			//  .fontSize(12).bold().end;
			//  firmaRut = new Txt(examen.user.RUT).absolutePosition(410,360).style('normal').fontSize(12).alignment('center').bold().end
			pdf.add(name);
			pdf.add(rut);

			/** Agregamos mensaje de fecha  de aprobacion */
			const aprobadoFecha = new Txt("Aprobado: " + moment(examen.codigo_unico).format("DD-MM-YYYY")).fontSize(16).alignment("center").absolutePosition(377, 480).style("normal").end;
			pdf.add(aprobadoFecha);
			/** Agregamos mensaje de vigencia */
			const vigencia = new Txt("Vigente hasta: " + moment(examen.vigenciaCert).format("DD-MM-YYYY")).fontSize(16).alignment("center").absolutePosition(350, 500).style("normal").end;

			pdf.add(vigencia);
			// pdf.add(new Txt('Haz aprobado el curso:').alignment('center').style('normal').end);
			/** Ponemos la data de la capacitacion */

			/** Ponemos la medalla */
			/*   const medalla: any = await this.getMedal();
      const imgMedal = await new Img(medalla).alignment('center').fit([50, 50]).margin([0,10,0,0]).build();
      pdf.add(imgMedal);*/

			/** Codigo de verificacion */
			const text2 = new Txt("Codigo de verificación").color("#777777").absolutePosition(260, 600).alignment("left").end;
			pdf.add(text2);
			/** Ponemos la imagen de verificacion */
			const imgBarcode = this.createBarcode(examen.codigo_unico);
			const barcodeImg = await new Img(imgBarcode).absolutePosition(260, 620).alignment("left").fit([120, 120]).build();
			pdf.add(barcodeImg);
			/** Ponemos el codigo QR para verificar el codigo */
			const qrcodeData = await this.createQrCode(this.baseUrl + "/verificador?codigo=" + examen.codigo_unico);
			console.log(qrcodeData);
			if (qrcodeData) {
				const textVerificar = new Txt("Verificar").absolutePosition(520, 600).alignment("left").color("#FFFFFF").end;
				const qr = await new Img(qrcodeData).absolutePosition(510, 620).alignment("left").fit([70, 70]).build();
				pdf.add(textVerificar);
				pdf.add(qr);
			}
			/** Ponemos el codigo QR para verificar todos los certificados de esta persona */
			const qrCodeVerifALLCAPS = await this.createQrCode(this.baseUrl + "/check?rut=" + examen.user.RUT);
			if (qrCodeVerifALLCAPS) {
				const textVerificarALL = new Txt("Certificados").absolutePosition(54, 600).alignment("left").color("#777777").end;
				const putQr = await new Img(qrCodeVerifALLCAPS).absolutePosition(50, 620).alignment("left").fit([70, 70]).build();
				pdf.add(textVerificarALL);
				pdf.add(putQr);
			}
			/**  */
			/** Colocamos la firma */
			// const raya = new Txt('_______________________').absolutePosition(400, 330).style('normal').alignment('center').fontSize(12).end;
			//  pdf.add(raya);
			//  pdf.add(firma1);
			//  pdf.add(firmaRut);
			//pdf.create().print('',window);

			if (getBase64) {
				const b64 = await this.getBase64FromPDF(pdf);
				return b64;
			}

			if (environment.production) {
				pdf.create().download("certificado_" + examen.codigo_unico + "_" + new Date().toLocaleDateString());
			} else {
				pdf.create().open("", window.open("", "_blank"));
				// pdf.create().download('certificado_'+examen.codigo_unico+"_"+new Date().toLocaleDateString());
			}
		} catch (err) {
			throw err;
		}
	}
	/** OBtener base64 del pdf del certificado */
	private getBase64FromPDF(pdf: PdfMakeWrapper): Promise<string> {
		return new Promise((res, rej) => {
			pdf.create().getBase64(
				(b64) => {
					res(b64);
				},
				(err) => {
					console.log(err);
					rej(err);
				}
			);
		});
	}

	private getImageLocalFromUrl(url: string) {
		return new Promise(async (res, rej) => {
			/** Hacemos un request get para obtener el logo de los assets */
			const response = await this.http.get(url, {responseType: "blob"}).toPromise();
			/** Obtenemos el lector de archivo */
			const reader = new FileReader();
			/** Una vez finalice el reader */
			reader.onloadend = () => {
				const base64Data = reader.result;
				//  console.log(base64Data);
				res(base64Data);
			};
			reader.onerror = () => {
				rej("Ocurrio un error");
			};
			reader.readAsDataURL(response);
		});
	}

	/*** Metodo para retornar el logo en base64 */
	private getLogo(): Promise<string | ArrayBuffer> {
		return new Promise(async (res, rej) => {
			/** Hacemos un request get para obtener el logo de los assets */
			const response = await this.http.get("assets/img/logo_pdf.png", {responseType: "blob"}).toPromise();
			/** Obtenemos el lector de archivo */
			const reader = new FileReader();
			/** Una vez finalice el reader */
			reader.onloadend = () => {
				const base64Data = reader.result;
				//  console.log(base64Data);
				res(base64Data);
			};
			reader.onerror = () => {
				rej("Ocurrio un error");
			};
			reader.readAsDataURL(response);
		});
	}
	/** Metodo para retornar la medalla en base64 */
	private getMedal(): Promise<string | ArrayBuffer> {
		return new Promise(async (res, rej) => {
			/** Hacemos un request get para obtener el logo de los assets */
			const response = await this.http.get("assets/img/medal.png", {responseType: "blob"}).toPromise();
			/** Obtenemos el lector de archivo */
			const reader = new FileReader();
			/** Una vez finalice el reader */
			reader.onloadend = () => {
				const base64Data = reader.result;
				// console.log(base64Data);
				res(base64Data);
			};
			reader.onerror = () => {
				rej("Ocurrio un error");
			};
			reader.readAsDataURL(response);
		});
	}

	/** Create barcode */
	private createBarcode(codigo: number) {
		const canvas = document.createElement("canvas");
		canvas.id = "barcode";
		jsBarcode(canvas, codigo.toString());
		return canvas.toDataURL();
	}
	/** createQrcode
	 * @param codigo codigo unico
	 */
	private async createQrCode(qrUrl) {
		try {
			/** Importamos la libreria */
			const qr = await import("qrcode");
			/** Creamos el canvas */
			const canvas = document.createElement("canvas");
			const url = await qr.toDataURL(canvas, qrUrl);
			return url;
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	/** Enviar PDF al correo del usuario
	 * @param examenId Id del examen a generar certificado y enviar por correo
	 */
	async sendPdfEmail(examenId: string) {
		try {
			/** Sacamos el examen */
			const examen: examen = await this._aFunctions.httpsCallable("getExamenById")({examenId: examenId}).toPromise();
			examen.capacitacion = await this._aFunctions.httpsCallable("getCapById")({capId: examen.capId}).toPromise();
			const pdf = await this.generateCertificado(examen, true);
			const pdf2 = await this.generateNormativas("enviar");
			/** Enviamos el pdf por correo */
			//  this._aFunctions.useEmulator('localhost',5001);
			const handler = this._aFunctions.httpsCallable("sendCert");
			const sendEmail = await handler({
				capName: examen.capacitacion.name,
				pdf: pdf,
				uid: examen.uid,
				// Hacemos un spit ya que recibimos la data en dataUri, y la necesitamos en solo base64
				normativas: pdf2.split(",")[1]
			}).toPromise();
			console.log(sendEmail);
			return;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	/**Descargar DAS */
	generateDas = (das: encuestaSegura, user: user, tipo: "DAS" | "LEY" = "DAS") => {
		const pdf = new PdfMakeWrapper();
		pdf.pageSize("A4");
		pdf.pageOrientation("portrait");
		pdf.pageMargins([40, 20, 40, 20]);
		pdf.info({
			title: "DAS"
		});
		switch (tipo) {
			case "DAS":
				pdf.add(
					new Txt(
						"En conformidad a lo dispuesto en el Decreto Supremo N° 40, Título IV, Artículo N° 21, De la Obligación de Informar los Riesgos Laborales”. La empresa en conocimiento de la Ley 16.744 sobre “Accidentes del Trabajo y Enfermedades Profesionales” ha informado a sus trabajadores de los riesgos asociados a la actividad a ejecutar y los Instructivos de Seguridad, con el objetivo de dar cumplimiento a la normativa legal vigente."
					)
						.alignment("justify")
						.margin([0, 10, 0, 10])
						.fontSize(10).end
				);
				// Agrefamos texto
				pdf.add(new Txt("Acta D.A.S.").alignment("center").fontSize(20).bold().end);
				break;
			case "LEY":
				pdf.add(new Txt(`MODELO DE PREVENCIÓN DE DELITOS`).alignment("justify").margin([0, 10, 0, 10]).fontSize(20).end);
				pdf.add(new Txt(`BENEO ORAFTI`).alignment("justify").margin([0, 10, 0, 10]).fontSize(20).end);
				break;
		}

		// Agregamos tabla

		pdf.add(new Table([...das.questions.map((d) => [d.check ? "√" : "", d.name], ...(das.otros ? [das.otros.check ? "√" : "", das.otros.text] : []))]).end);

		switch (tipo) {
			case "DAS":
				pdf.add(
					new Txt(
						"Declaro haber sido informado acerca de los riesgos inherentes que implican las labores que se realizan dentro de la Planta Beneo-Orafti, así como las medidas preventivas necesarias para controlarlos. Quedo en claro conocimiento que el no cumplimiento de lo antes expresado, me expone a las sanciones que la legislación laboral estipula al respecto"
					)
						.alignment("justify")
						.margin([0, 10, 0, 10])
						.fontSize(10).end
				);
				break;
			case "LEY":
				pdf.add(new Txt("El Manual del Modelo de Prevención de Delitos, se encuentra disponible en la página web de Beneo Orafti Chile S.A. siendo de libre acceso.").alignment("justify").margin([0, 10, 0, 10]).fontSize(10).end);
				pdf.add(
					new Txt(
						`Declaro conocer la existencia, funcionamiento del Canal de Denuncias en el sitio
            web de Beneo Orafti Chile S.A. con el fin de denunciar actos que pueden ser
            constitutivos de delitos consagrados en el MPD.`
					)
						.alignment("justify")
						.margin([0, 10, 0, 10])
						.fontSize(10).end
				);
				break;
		}
		pdf.add(
			new Table([
				["", "Nombre:", `${user.firstname} ${user.lastname}`, ""],
				["", "RUT:", `${user.RUT || ""}`, ""],
				["", "Cargo", `${user.cargo || ""}`, ""]
			])

				.width("auto")
				.layout("lightHorizontalLines").end
		);
		pdf.add(new Txt("√ Estoy de acuerdo").alignment("justify").fontSize(15).margin([0, 10, 0, 10]).bold().end);
		if (environment.production) {
			pdf.create().download(`DAS-${user.firstname}-${user.lastname}-${user.RUT}-${new Date().toLocaleDateString()}.pdf`);
		} else {
			pdf.create().open("", window.open("", "_blank"));
			// pdf.create().download('certificado_'+examen.codigo_unico+"_"+new Date().toLocaleDateString());
		}
	};
}

